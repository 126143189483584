import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { TokenService } from '../../auth/services/token.service';

@Injectable()
export class AddRequestHeaderInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request.clone({ headers: this.headers(request) }));
  }

  headers(req: HttpRequest<unknown>): HttpHeaders {
    const headers = {};

    if (!req.headers.has('Content-Type')) {
      headers['Content-Type'] = req.url.includes(environment.newApi)
        ? 'application/json'
        : 'application/x-www-form-urlencoded';
    }

    const auth_header = req.headers.get('Authorization') ?? this.tokenService.getAccessToken();
    if (auth_header) {
      headers['Authorization'] = auth_header;
    }

    return new HttpHeaders(headers);
  }
}
