import { Component, Input } from '@angular/core';

import { NbCardModule, NbDialogRef } from '@nebular/theme';

@Component({
  standalone: true,
  selector: 'ngx-session-timout-dialog',
  template: `
    <nb-card>
      <nb-card-header class="d-flex align-items-end">
        <div class="mr-2">Session timeout. Please re-login.</div>
        <div class="fa fa-times close-btn" (click)="close()"></div>
      </nb-card-header>
    </nb-card>
  `,
  styles: [
    `
      nb-card {
        border: none;
        position: relative;
      }

      .close-btn {
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;
        position: absolute;
        color: #0095d3;
        font-size: 1rem !important;
        margin: 0 !important;
      }
    `,
  ],
  imports: [NbCardModule],
})
export class SessionTimoutDialogComponent {
  @Input() anyActionFlag: boolean;

  constructor(protected dialogRef: NbDialogRef<SessionTimoutDialogComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
