import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CachedRouteReuseStrategy implements RouteReuseStrategy {
  storedRoutes: Map<string, DetachedRouteHandle> = new Map();

  /**
   * Determines if this route (and its subtree) should be detached to be reused later
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // TODO: return FALSE if the tab was closed
    return Boolean(route.data['allow_caching']);
  }

  /**
   * Stores the detached route.
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (handle) this.storedRoutes.set(this.getKey(route), handle);
  }

  /**
   * Determines if this route (and its subtree) should be reattached
   * @param route Stores the detached route.
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return Boolean(route.data['allow_caching'] && this.storedRoutes.get(this.getKey(route)));
  }

  /**
   * Retrieves the previously stored route
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRoutes.get(this.getKey(route)) ?? null;
  }

  /**
   * Retrieves the previously stored route
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data['allow_caching']) return this.getKey(future) === this.getKey(curr);
    return future.routeConfig === curr.routeConfig;
  }

  /**
   * Get a key for a route to use as key for the handles map
   * @param route Route
   * @returns
   */
  private getKey(route: ActivatedRouteSnapshot) {
    return this.getResolvedUrl(route);
  }

  /**
   * returns full path of an `ActivatedRouteSnapshot`
   * including the query parameters
   * @param route Route
   * @returns url
   */
  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    let url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    const queryParam = route.queryParamMap;
    if (queryParam.keys.length > 0) {
      url +=
        '?' +
        queryParam.keys
          .map((key) =>
            queryParam
              .getAll(key)
              .map((value) => key + '=' + value)
              .join('&'),
          )
          .join('&');
    }
    return url;
  }
}
