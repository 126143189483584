import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NbToastrService } from '@nebular/theme';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../../auth/services/auth.service';
import { HIDE_ERRORS } from '../constants';

@Injectable()
export class ResponseCheckInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: NbToastrService,
    private authService: AuthService,
    private logger: NGXLogger,
  ) {
    this.logger.updateConfig({ ...this.logger.getConfigSnapshot(), context: 'INTERCEPTOR' });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(({ error, status }: HttpErrorResponse) => {
        let errorMsg = '';
        if (error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error Code: ${error.errorCode ?? status},  Message: ${error.message}`;
        }
        if (error?.validationErrors) {
          for (const validationError of error.validationErrors) {
            this.toastrService.danger(validationError.message, '', {
              toastClass: 'no-title-toast',
            });
          }
        } else if (error?.message) {
          this.toastrService.danger(error.message, '', {
            toastClass: 'no-title-toast',
          });
        }

        if (
          (status === 401 || error.errorCode === '#ER-SY0002') &&
          error.errorCode !== '#ER-SY0004' &&
          error.errorCode !== '#ER-VA7074'
        ) {
          this.authService.clearUserData();
          setTimeout(() => {
            window.location.href = '/auth/login';
          }, 10000);

          return;
        }

        return throwError(() => ({
          message: errorMsg,
          errorCode: error.errorCode,
          toString: () => errorMsg,
        }));
      }),
      map((event) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          try {
            if (body?.userMessages) {
              const logLevel = {
                2: 'info',
                3: 'warning',
                4: 'danger',
              };
              let hiddenErrors: string[] = [];
              if (request.context.get(HIDE_ERRORS)?.length) {
                hiddenErrors = request.context.get(HIDE_ERRORS);
                this.logger.warn('Hidden errors codes list:', hiddenErrors);
              }
              for (const message of body.userMessages) {
                if (
                  message.errorCode === '#ER-VA7071' ||
                  message.errorCode === '#ER-VA7074' ||
                  hiddenErrors.includes(message.errorCode)
                ) {
                  continue;
                }
                this.toastrService[logLevel[message.level]](message.message, '', {
                  toastClass: 'no-title-toast',
                  duration: 15000,
                });
              }
            }
          } catch (e) {
            this.logger.warn(e);
          }
        }
        return event;
      }),
    );
  }
}
